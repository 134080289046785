import React, {useState, useEffect, useRef} from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Formik } from 'formik'
import { Grid, Row, Col, th, Box } from '@smooth-ui/core-sc'

import { Label, SectionTitle, Result, ResultLabel, ResultsContainer } from './styles'
import { PaceCalculatorInput } from './PaceCalculatorInput'
import { Button } from '../Button/Button'
import { Dropdown } from '../Dropdown/Dropdown'
import { VerticalSpacer, AbsoluteContainer, MainSection, SectionScrollAnchor } from '../../style/globalStyleComponents'
import { toSeconds, fromSeconds, twoDigitsFrom } from '../../utils/utils'

const RUN_TYPE = {
  '5K': '5k',
  '10K': '10k',
  'HALF_MARATHON': 'Half Marathon',
  'MARATHON': 'Marathon',
}
const CALCULATE_USING = {
  PACE: 'pace',
  TIME: 'time',
}
const DISTANCE_UNIT = {
  KM: 'km',
  MILE: 'mile',
}
const MULTIPLIER = {
  [DISTANCE_UNIT.KM]: 1.6,
  [DISTANCE_UNIT.MILE]: 1,
}
const TRAINING_WEIGHTS = {
  EASY: [1.26875, 1.38125],
  TEMPO: [1.12291667, 1.16041667],
  '5K': [1.11666667, 1.1375],
  FARTLEK: [1.11666667, 1.1375],
  '200M': [0.10208333, 0.1125],
  '400M': [0.24166667, 0.25625],
  '800M': [0.50416667, 0.52708333],
  '1200M': [0.77708333, 0.83125],
  '1600M': [1.08541667, 1.11875],
}
const DISTANCES = { // in miles
  [RUN_TYPE["5K"]]: 3.106,
  [RUN_TYPE["10K"]]: 6.213,
  [RUN_TYPE.HALF_MARATHON]: 13.1,
  [RUN_TYPE.MARATHON]: 26.2,
}
const DISTANCES_FOR_FASTEST_PACE = {
  [RUN_TYPE["5K"]]: 3.47083333,
  [RUN_TYPE["10K"]]: 7.20833333,
  [RUN_TYPE.HALF_MARATHON]: 16.0708333,
  [RUN_TYPE.MARATHON]: 33.8229167,
}
const initialValues = {
  runType: undefined,
  calculateUsing: undefined,
  distanceUnit: DISTANCE_UNIT.MILE,
  averagePace: '0:0',
  time: '0:0:0',
}

export const PaceCalculator = (props) => {
  const [results, setResults] = useState()
  const form = useRef();
  useEffect(() => {
    if (form.current) {
      form.current.getFormikActions().validateForm();
    }
  });

  const { map } = useStaticQuery(graphql`
  {
    map: file(name: { eq: "dark-map" }) {
      childImageSharp{
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
  `)

  const calculatePace = (values, {setFieldValue}) => {
    let averagePaceInSeconds
    let fastestPaceInSeconds

    if (values.calculateUsing === CALCULATE_USING.TIME) {
      const timeInSeconds = toSeconds(values.time)
      fastestPaceInSeconds = timeInSeconds / DISTANCES_FOR_FASTEST_PACE[values.runType] * MULTIPLIER[values.distanceUnit]
      averagePaceInSeconds = timeInSeconds / DISTANCES[values.runType] * MULTIPLIER[values.distanceUnit]
    } else {
      averagePaceInSeconds = toSeconds(values.averagePace)
      fastestPaceInSeconds = averagePaceInSeconds
      const timeFormPace = averagePaceInSeconds * DISTANCES[values.runType] * MULTIPLIER[values.distanceUnit]
      setFieldValue('time', fromSeconds(timeFormPace))
    }

    const results = Object.keys(TRAINING_WEIGHTS).reduce((acc, curr) => {
      const range = TRAINING_WEIGHTS[curr]
      acc[curr] = range
        .map(weight => weight * fastestPaceInSeconds * MULTIPLIER[values.distanceUnit])
        .map(fromSeconds)
      return acc
    }, {})

    setResults(results)
    setFieldValue('averagePace', fromSeconds(averagePaceInSeconds))
    console.log('set field herer');
  }

  return (
    <MainSection dark>
      <SectionScrollAnchor ref={props.innerRef} />
      <Grid css={`position: relative; z-index: 2`} pb="60px">
        <Row>
          <Col xs={12} lg={6}>
            <VerticalSpacer left>
              <h1 css={`color: ${th('primary')}`}>{props.title}</h1>
              <h3>{props.subtitle}</h3>
            </VerticalSpacer>
          </Col>
        </Row>
            <Formik
              ref={form}
              isInitialValid={false}
              initialValues={initialValues}
              onSubmit={calculatePace}
              validate={(values) => {
                const errors = {}
                if (!values.runType) {
                  errors.runType = true
                }
                if (!values.calculateUsing) {
                  errors.calculateUsing = true
                }
                if (values.calculateUsing === CALCULATE_USING.PACE) {
                  if (values.averagePace === initialValues.averagePace) {
                    errors.averagePace = true
                  }
                } else if (values.calculateUsing === CALCULATE_USING.TIME) {
                  if (values.time === initialValues.time) {
                    errors.time = true
                  }
                }
                return errors
              }}>
              {({ values, errors, touched, handleSubmit, setFieldValue, setFieldTouched }) => {
                const [paceMin, paceSec] = values.averagePace.split(':')
                const [hours, minutes, seconds] = values.time.split(':')

                return (
                  <Row>
                    <Col xs={12} lg={4} mb="30px">
                      <form onSubmit={handleSubmit}>
                        <Box mb="15px">
                          <Label>Pick run type</Label>
                          <Dropdown
                            aria-label='pick run type'
                            expand
                            name="runType"
                            value={values.runType}
                            onChange={(e) => {
                              setFieldValue('runType', e.target.value)
                              setFieldTouched('runType', true)
                            }}>
                            <option value="">Select</option>
                            <option value={RUN_TYPE["5K"]}>5K</option>
                            <option value={RUN_TYPE["10K"]}>10K</option>
                            <option value={RUN_TYPE.HALF_MARATHON}>Half Marathon</option>
                            <option value={RUN_TYPE.MARATHON}>Marathon</option>
                          </Dropdown>
                        </Box>

                        <Box mb="15px">
                          <Label>Calculate using</Label>
                          <Dropdown
                            aria-label='Calculate using'
                            expand
                            name="calculateUsing"
                            disabled={!values.runType}
                            value={values.calculateUsing}
                            onChange={(e) => setFieldValue('calculateUsing', e.target.value)}>
                            <option value="">Select</option>
                            <option value={CALCULATE_USING.PACE}>Pace</option>
                            <option value={CALCULATE_USING.TIME}>Time</option>
                          </Dropdown>
                        </Box>

                        {values.calculateUsing === CALCULATE_USING.PACE && (
                          <Box mb="15px">
                            <Label>Pace</Label>
                            <Row>
                              <Col>
                                <PaceCalculatorInput
                                  aria-label='average pace per minute'
                                  expand
                                  type="number"
                                  value={paceMin}
                                  onChange={(e) => setFieldValue('averagePace', `${e.target.value}:${paceSec}`)} />
                              </Col>

                              <Col>
                                <PaceCalculatorInput
                                  expand
                                  aria-label='average pace per second'
                                  type="number"
                                  value={paceSec}
                                  onChange={(e) => setFieldValue('averagePace', `${paceMin}:${e.target.value}`)} />
                              </Col>

                              <Col>
                                <Dropdown
                                  aria-label='distance Unit'
                                  expand
                                  value={values.distanceUnit}
                                  name="distanceUnit"
                                  onChange={(e) => setFieldValue('distanceUnit', e.target.value)}>
                                  <option value={DISTANCE_UNIT.MILE}>per mile</option>
                                  <option value={DISTANCE_UNIT.KM}>per km.</option>
                                </Dropdown>
                              </Col>
                            </Row>
                          </Box>
                        )}

                        {values.calculateUsing === CALCULATE_USING.TIME && (
                          <Box mb="15px">
                            <Label>Time</Label>
                            <Row>
                              <Col>
                                <PaceCalculatorInput
                                  aria-label='distance Unit'
                                  expand
                                  type="number"
                                  value={hours}
                                  onChange={(e) => setFieldValue('time', `${e.target.value}:${minutes}:${seconds}`)} />
                              </Col>
                              <Col>
                                <PaceCalculatorInput
                                  aria-label='minutes'
                                  expand
                                  type="number"
                                  value={minutes}
                                  onChange={(e) => setFieldValue('time', `${hours}:${e.target.value}:${seconds}`)} />
                              </Col>
                              <Col>
                                <PaceCalculatorInput
                                  aria-label='seconds'
                                  expand
                                  type="number"
                                  value={seconds}
                                  onChange={(e) => setFieldValue('time', `${hours}:${minutes}:${e.target.value}`)} />
                              </Col>
                            </Row>
                          </Box>
                        )}

                        <Box mt="30px">
                          <Button disabled={Object.keys(errors).length !== 0} expand
                                  aria-label='calculate'>Calculate</Button>
                        </Box>
                      </form>
                    </Col>
                    <Col xs={12} lg={7} olg={1}>
                      {renderResults(results, values.distanceUnit, values.time, values.averagePace, props.content)}
                    </Col>
                  </Row>
                )
              }}
            </Formik>
      </Grid>
      <AbsoluteContainer>
        <Img css={`height: 100%;`} fluid={map.childImageSharp.fluid} />
      </AbsoluteContainer>
    </MainSection>
  )
}

const renderResults = (results, distanceUnit, time, averagePace, content) => {
  if (typeof results === 'undefined') {
    return (
      <ResultsContainer
        mb="30px"
        p="20px"
        dangerouslySetInnerHTML={{ __html: content }} />
    )
  }

  const { EASY, ...rest } = results
  const [left, right] = EASY

  return (
    <ResultsContainer mb="30px">
      <Col xs={12} lg={6}>
        <SectionTitle>Time & Pace Projection</SectionTitle>
        <Row>
          <Col xs={6} gutter={0}>
            <ResultLabel>Projected Time</ResultLabel>
            <ResultLabel>Pace (min/{distanceUnit})</ResultLabel>
          </Col>
          <Col xs={6} gutter={0}>
            <Result>{time}</Result>
            <Result>{averagePace}</Result>
          </Col>
        </Row>

        <SectionTitle>Endurance workouts</SectionTitle>
        <Box textAlign="center" mt="-10px" mb="10px">Per {distanceUnit}</Box>
        <Row>
          <Col xs={6} gutter={0}>
            <ResultLabel>Long runs:</ResultLabel>
            <ResultLabel>Short runs:</ResultLabel>
          </Col>
          <Col xs={6} gutter={0}>
            <Result>{left} - {right}</Result>
            <Result>{left} - {right}</Result>
          </Col>
        </Row>

      </Col>
      <Col xs={12} lg={6}>
        <SectionTitle>Stamina Workouts</SectionTitle>
        <Box textAlign="center" mt="-10px" mb="10px">Minutes</Box>
        <Row>
          <Col xs={6} gutter={0}>
            {Object.keys(rest).map((training, index) => {
            const [left, right] = results[training]
            return (
              <ResultLabel key={index}>{training}:</ResultLabel>
            )
          })}
          </Col>
          <Col xs={6} gutter={0}>
            {Object.keys(rest).map((training, index) => {
              const [left, right] = results[training]
              return (
                <Result key={index}>{left} - {right}</Result>
              )
            })}
          </Col>
        </Row>
      </Col>
    </ResultsContainer>
  )
}

PaceCalculator.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
}